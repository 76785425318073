export let lazyImageObserver = new IntersectionObserver(function(entries, observer) {

    // Loop through IntersectionObserverEntry objects
    entries.forEach(function(entry) {
        // Do these if the target intersects with the root
        if (entry.isIntersecting) {
            let lazyImage = entry.target;
            lazyImage.classList.remove("h-0");

            if(lazyImage.dataset.srcset !== undefined && lazyImage.dataset.srcset !== "") {
                lazyImage.srcset = lazyImage.dataset.srcset;
            }

            if(lazyImage.dataset.src !== undefined && lazyImage.dataset.src !== "") {
                lazyImage.src = lazyImage.dataset.src;
            }

            lazyImage.classList.remove("lazy");
            lazyImageObserver.unobserve(lazyImage);
        }
    });
});
