/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';

import { lazyImageObserver } from './lazyObserver.js';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';
import $ from 'jquery';

//import * as Turbo from '@hotwired/turbo';
// ... lines 2 - 5
//Turbo.session.drive = false;

global.$ = global.jQuery = $;
global.touchEvent = 'ontouchstart' in window ? 'click' : 'click';
global.lang = window.location.pathname.slice(1,3);
global.connect = window.location.pathname.includes('/connect/');

const formatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2});

window.addEventListener('load', ()=>{

  $('#checkboxProdotti').on('change', function() {
    console.log($(this).is(":checked"))

    window.location = '/it/set-prodotti-dealer?dealer=' + $(this).is(":checked");
  });

  document.querySelectorAll('.box-custom:not([data-itemid=""])').forEach(element => {
    let estensione = element.dataset.estensioneallegato.split('.').pop(),
        preview = element.querySelector('img'),
        tipo = element.closest('.container-carrello').dataset.tipo;

    switch(estensione.toLowerCase()) {
      case 'xls':
      case 'xlsx': preview.setAttribute('data-src', '/build/images/microsoft-excel.svg'); break;
      case 'doc':
      case 'docx': preview.setAttribute('data-src', '/build/images/microsoft-word.svg'); break;
      case 'ppt':
      case 'pptx': preview.setAttribute('data-src', '/build/images/microsoft-powerpoint.svg'); break;
      case 'pdf': preview.setAttribute('data-src', '/build/images/pdf-file.svg'); break;
      case 'png':
      case 'jpg':
      case 'jpeg':
        preview.setAttribute('data-src', '/en/' + (connect ? '/connect/' : '') + (tipo == 'offerta' ? 'offerte' : 'ordini') + '-item/' + element.dataset.itemid + '/allegato'); break;
      default: preview.setAttribute('data-src', '/build/images/noimage_150.png'); break;
    }
  });

  if('serviceWorker' in navigator){
    try {
      navigator.serviceWorker.register('/serviceWorker.js');
      //console.log("Service Worker Registered");
    } catch (error) {
      //console.log("Service Worker Registration Failed");
    }
  } else {
    //console.log(navigator);
  }

  if(document.getElementById('hamburger')) {
    document.getElementById('hamburger').addEventListener(touchEvent, function () {
      this.classList.toggle("is-active");
      document.getElementById("menu-header").classList.toggle("hidden");
    }, false);
  }

  if(document.getElementsByClassName('cerca-codice').length > 0) {
    Array.from(document.getElementsByClassName('cerca-codice')).forEach(function(input) {
      input.addEventListener(touchEvent, function (event) {
        event.preventDefault();

        cercaMatricola(this.closest('div.container-ricerca-matricola'));
      }, false);
    });
  }

  Array.from(document.getElementsByClassName('input-matricola')).forEach(function(input) {
    input.addEventListener("keyup", function(e) {
      if(e.key == "Enter") {
        cercaMatricola(this.closest('div.container-ricerca-matricola'));
      }
    }, false);
  });
  // caricamento immagini lazy
  var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
  var videos = [].slice.call(document.getElementsByTagName("video"));

  if ("IntersectionObserver" in window) {

    let videoObserver = new IntersectionObserver(function(entries, observer) {

      // Loop through IntersectionObserverEntry objects
      entries.forEach(function(entry) {
        // Do these if the target intersects with the root
          let video = entry.target;
        if (entry.isIntersecting) {
          video.play();
          videoObserver.unobserve(video);
        } else {
          video.pause();
        }
      });
    }, {
      threshold: 0.5,
    });

    // Loop through and observe each image
    lazyImages.forEach(function(lazyImage) {
        lazyImageObserver.observe(lazyImage);
    });

    videos.forEach(function(video) {
      if(!video.classList.contains('manual-video')) {
        videoObserver.observe(video);
      }
    });

  }

  $('.container-carrello').on('click', '.mostra_accessori_carrello', function(e) {
    let collegati = this.closest('.column-carrello-item').querySelector('.column-carrello-item-collegati');

    if(collegati !== undefined) {
      if(collegati.classList.contains('hidden')) {
        collegati.classList.remove('hidden');
        this.textContent = this.dataset.nascondi;
      } else {
        collegati.classList.add('hidden');
        this.textContent = this.dataset.mostra;
      }
    }
  });

  $('.container-carrello').on('click', '.rimuovi-articolo', function(e) {
    let item = this.closest('.item-carrello');

    this.classList.add('rimuovi-articolo-incorso');

    let tipoCarrello = this.closest('.container-carrello').dataset.tipo,
        pathAPI = getPathApiCarrello(tipoCarrello);

    fetch("/" + lang + (connect ? '/connect' : '') + "/" + pathAPI + "/articoli/" + item.dataset.itemid, {
      method: "DELETE"
    }).then(res => {
      return res.json();
    }).then(data => {
      if(data.error == undefined) {
        this.classList.remove('rimuovi-articolo-incorso');

        item.style.opacity = 0;

        setTimeout(function(){
          rimuoviRigaCarrello(item.dataset.itemid, tipoCarrello);
        }, 700);

        // aggiorno totale carrello
        aggiornaTotaleMenuCarrello(Object.keys(data.carrello.items).length, tipoCarrello);

        aggiornaTotaliCarrello(data.carrello);
      }
    });
  });

  $('.container-carrello').on('click', '.quantita-carrello-prodotto', function(e) {

      let item = this.closest('.item-carrello'),
          nuovaQuantita = Number(item.querySelector('.text-item-quantita').textContent) + ( this.textContent == '－' ? -1 : 1 );

      item.querySelector('.quantita-loader').classList.remove('hidden');

      let tipoCarrello = this.closest('.container-carrello').dataset.tipo,
          pathAPI = getPathApiCarrello(tipoCarrello);

      fetch("/" + lang + (connect ? '/connect' : '') + "/" + pathAPI + "/articoli/" + item.dataset.itemid + "/quantita", {
        method: "PATCH",
        body: JSON.stringify({ quantita: nuovaQuantita })
      }).then(res => {
        return res.json();
      }).then(data => {

        if(data.error == undefined) {
          magicCheck(item.querySelector('.quantita-ok-icon'), true);
          item.querySelector('.quantita-loader').classList.add('hidden');

          if(nuovaQuantita === 0) {
            item.style.opacity = 0;

            setTimeout(function(){
              rimuoviRigaCarrello(item.dataset.itemid, tipoCarrello);
            }, 700);

            // aggiorno totale carrello
            aggiornaTotaleMenuCarrello(Object.keys(data.carrello.items).length, tipoCarrello);
          } else {
            // modifica tutti i dati
            Object.keys(data.carrello.items).forEach(key => {
              let carrelloItem = data.carrello.items[key];

              if(carrelloItem.id != item.dataset.itemid) {
                return;
              }

              modificaQuantitaCarrello(item.dataset.itemid, carrelloItem, tipoCarrello);
            });
          }

          aggiornaTotaliCarrello(data.carrello);
        }


      }).finally(function() { item.querySelector('.quantita-loader').classList.add('hidden'); });
    });

  scrollTo();
});

const terminationEvent = 'onpagehide' in self ? 'pagehide' : 'unload';

window.addEventListener(terminationEvent, (event) => {
  // Note: if the browser is able to cache the page, `event.persisted`
  // is `true`, and the state is frozen rather than terminated.
}, {capture: true});

/**
 * Modifica la quantità di un elemento nel carrello mini e grande
 * @param itemId Id dell'elemento da modificare
 * @param carrelloItem Dati con cui sostituire quelli visualizzati, oggetto item del carrello
 */
function modificaQuantitaCarrello(itemId, carrelloItem, tipoCarrello) {

  // se la nuova quantità è zero allora rimuovo la riga, altrimenti modifico campo quantità
  if(carrelloItem.item_quantita === 0) {
    setTimeout(function(){
      rimuoviRigaCarrello(itemId);
    }, 700);
  } else {
    document.querySelectorAll('.container-carrello[data-tipo="' + tipoCarrello + '"]').forEach(carrello => {
      let item = carrello.querySelector('.item-carrello[data-itemid="' + itemId + '"]');

      item.querySelector('.text-item-quantita').textContent = carrelloItem.item_quantita;

      if (item.querySelector('.text-item-prezzo')) {
        item.querySelector('.text-item-prezzo').textContent = "€" + formatter.format(carrelloItem.totale_configurazione);
        item.dataset.totaleconfigurazione = carrelloItem.totale_configurazione;
      }

      if (item.querySelector('.text-item-prezzo-maggiorato')) {
        item.querySelector('.text-item-prezzo-maggiorato').textContent = "€" + formatter.format(carrelloItem.totale_configurazione_maggiorato);
        item.dataset.totalemaggiorato = carrelloItem.totale_configurazione_maggiorato;
      }

      if (item.querySelector('.maggiorazione-assoluta')) {
        let maggiorazioneAssoluta = item.dataset.totaleconfigurazione * carrelloItem.maggiorazione / 100;
        item.querySelector('.maggiorazione-assoluta').textContent = formatter.format(maggiorazioneAssoluta.toFixed(2));
      }

      if (item.querySelector('.sconto-assoluto')) {
        let scontoAssoluto = item.dataset.totalemaggiorato *  carrelloItem.sconto / 100;
        item.querySelector('.sconto-assoluto').textContent = formatter.format(scontoAssoluto.toFixed(2));
      }

      if (item.querySelector('.text-item-prezzo-scontato')) {
        item.querySelector('.text-item-prezzo-scontato').textContent = "€" + formatter.format(carrelloItem.totale_configurazione_scontato);
      }

      if (item.querySelector('.text-item-prezzo-ivato')) {
        item.querySelector('.text-item-prezzo-ivato').textContent = "€" + formatter.format(carrelloItem.totale_configurazione_ivato);
      }
    });
  }
}

function aggiornaRigheCarrello(carrello, item, tipoCarrello) {
  // modifica tutti i dati
  Object.keys(carrello.items).forEach(key => {
    let carrelloItem = carrello.items[key];

    if(carrelloItem.id != item.dataset.itemid) {
      return;
    }

    modificaQuantitaCarrello(item.dataset.itemid, carrelloItem, tipoCarrello);
  });
}

/**
 * Rimuove riga articolo da carrello mini e pagina
 */
function rimuoviRigaCarrello(itemId, tipoCarrello) {
  document.querySelectorAll('.container-carrello[data-tipo="' + tipoCarrello + '"]').forEach(carrello => {
    carrello.querySelector('.item-carrello[data-itemid="' + itemId + '"]').remove();

    let itemsCarrello = carrello.querySelectorAll('.item-carrello');

    if(itemsCarrello !== null) {
      for(let j = 0; j < itemsCarrello.length; j++) {
        itemsCarrello[j].querySelector('.numberCircle').textContent = j + 1;
      }
    }
  });
}

// aggiorna i conti totali del carrello
function aggiornaTotaliCarrello(carrello) {

  if(document.querySelector('.text-totale-prezzo')) {
    document.querySelector('.text-totale-prezzo').textContent = "€" + formatter.format(carrello.totale);
  }

  if(document.querySelector('.text-totale-prezzo-maggiorato')) {
    document.querySelector('.text-totale-prezzo-maggiorato').textContent = "€" + formatter.format(carrello.totale_maggiorato);
  }

  if(document.querySelector('.text-totale-prezzo-scontato')) {
    document.querySelector('.text-totale-prezzo-scontato').textContent = "€" + formatter.format(carrello.totale_scontato);
  }

  if(document.querySelector('.text-totale-prezzo-ivato')) {
    document.querySelector('.text-totale-prezzo-ivato').textContent = "€" + formatter.format(carrello.totale_ivato);
  }
}

function cercaMatricola(divMatricola) {
  let inputMatricola = $(divMatricola).find('input[name=matricola]'),
      matricola = inputMatricola.val(),
      macchinaId = '';// inputMatricola.data('macchina') !== undefined ? inputMatricola.data('macchina') : '';

  $.get('/it/assistenza/ricerca-matricola?matricola=' + matricola + '&macchinaId=' + macchinaId, function( data ) {
    if(data.result == 'ok') {
      window.location =  "/" + lang + "/support-index/" + matricola
    } else {
      $(divMatricola).find('.errore-matricola').removeClass('hidden');
    }
  });
}

/**
 * Aggiorna il totale nella icona del menu
 *
 * @param numItems numero di elementi totali aggiornato
 */
function aggiornaTotaleMenuCarrello(numItems, tipoCarrello) {
  let menuCarrello = document.querySelector('.menu-carrello[data-tipo=' + tipoCarrello + ']');

  if(menuCarrello === undefined || menuCarrello === null) return false;

  if(numItems > 0) {
    menuCarrello.querySelector('p').textContent = numItems;
    menuCarrello.querySelector('p').classList.remove('hidden');
    menuCarrello.classList.add('menu-carrello-pieno');
  } else {
    menuCarrello.classList.remove('menu-carrello-pieno');
    menuCarrello.querySelector('p').classList.add('hidden');
  }
}

global.zallysCarrello = {
  totaliCarrello: function(carrello) {
    aggiornaTotaliCarrello(carrello);
  },
  righeCarrello: function(carrello, item, tipoCarrello) {
    aggiornaRigheCarrello(carrello, item, tipoCarrello);
  },
  menuCarrello: function(tipoCarrello) {
    $.get("/" + lang + "/partial-carrello-" + (tipoCarrello == 'ordine' ? 'ordini' : 'offerte'), function( data ) {
      $('.column-carrello[data-tipo="' + tipoCarrello + '"]').html($(data).html());

      var lazyImages = [].slice.call(document.querySelectorAll('.column-carrello[data-tipo=\"' + tipoCarrello + '\"] img.lazy'));

      lazyImages.forEach(function(lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });

      aggiornaTotaleMenuCarrello($('.column-carrello[data-tipo=\"' + tipoCarrello + '\"] .item-carrello').length, tipoCarrello);
    });
  }
}

function scrollTo() {
  const links = document.querySelectorAll('.scrollTo');
  links.forEach(each => (each.onclick = scrollAnchors));
}

function scrollAnchors(e, respond = null) {
  const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
  e.preventDefault();
  var targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
  const targetAnchor = document.querySelector(targetID);
  if (!targetAnchor) return;
  const originalTop = distanceToTop(targetAnchor);
  window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
  const checkIfDone = setInterval(function() {
    const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (distanceToTop(targetAnchor) === 0 || atBottom) {
      targetAnchor.tabIndex = '-1';
      targetAnchor.focus();
      window.history.pushState('', '', targetID);
      clearInterval(checkIfDone);
    }
  }, 100);
}

function getPathApiCarrello(tipoCarrello) {
  switch (tipoCarrello) {
    case 'ordine': return 'carrello-ordini';
    case 'offerta': return 'carrello-offerte';
  }

  return 'carrello-ordini';
}

/**
 * Controlla hide/show della spunta per confermare modifica
 * @param element
 * @param show
 */
function magicCheck(element, show) {
  if(show) {
    element.style.opacity = 1;
    setTimeout(magicCheck, 1200, element, false);
  } else {
    element.style.opacity = 0;
  }
}

global.zallysHistory = {
  back: function(url) {
    if (history.length > 2) {
      // if history is not empty, go back:
      if(document.referrer != "") {
        window.location = document.referrer;
      } else {
        window.history.back();
      }
    } else if (url) {
      // go to specified fallback url:
      window.history.replaceState(null, null, url);
    } else {
      // go home:
      window.history.replaceState(null, null, '/');
    }
  }
};

global.modale = {

  mostra: function(modalElement) {
    modalElement.classList.remove('hidden');
    let blackout = document.querySelector('.body-blackout');
    blackout.classList.add('is-blacked-out');

    document.addEventListener('click', function (event) {
      if(modalElement.firstChild !== event.target && !modalElement.contains(event.target)){
        console.log('clicking outside the div');
      }
    })
  },

  nascondi: function(modalElement) {
    modalElement.classList.add('hidden');
    document.querySelector('.body-blackout').classList.remove('is-blacked-out');
  },

  confirm: function(modalElement, yesCallback, noCallback) {
    modale.mostra(modalElement);

    modalElement.querySelector('#bottoneConferma').addEventListener('click', function() {
      modale.nascondi(modalElement);
      yesCallback();
    });

    modalElement.querySelector('#bottoneAnnulla').addEventListener('click', function() {
      modale.nascondi(modalElement);
      noCallback();
    });

    modalElement.querySelector('.close-button').addEventListener('click', function() {
      modale.nascondi(modalElement);
      noCallback();
    });
  }
}

if(document.getElementById('modal-produzione')) {
  global.modale.aggiornaModaleProduzione = function(codiceProdotto) {
    return fetch("/it/products/righe-produzione/" + codiceProdotto, {
      method: "GET"
    }).then(res => {

      if (res.status >= 200 && res.status < 400) {
        return res.text();
      }

      return false;
    }).then(html => {
      if(html == false) {
        return false;
      }

      document.getElementById('righe-produzione').innerHTML = html;

      return true;
    });
  }
}

if(document.getElementById('modal-esistenze')) {
  global.modale.aggiornaModaleEsistenze = function(codiceProdotto) {
    return fetch("/" + global.lang + "/products/tabella-esistenze/" + codiceProdotto, {
      method: "GET"
    }).then(res => {

      if (res.status >= 200 && res.status < 400) {
        return res.text();
      }

      return false;
    }).then(html => {
      if(html == false) {
        return false;
      }

      document.getElementById('righe-esistenze').innerHTML = html;

      var rotationalTogglers = document.getElementsByClassName('rotational-toggler');

      for (var i = 0; i < rotationalTogglers.length; i++) {
        rotationalTogglers[i].addEventListener('click', function() {
          this.classList.toggle('rotational-toggler-open');
          this.closest('div.matricola-container').querySelector('div.accessori').classList.toggle('hidden');
        });
      }

      return true;
    });
  }

  global.modale.aggiornaModaleEsistenzeTotale = function(categoriaId, keyword) {
    return fetch("/" + global.lang + "/prodotti/tabella-esistenze-totale?categoriaId=" + categoriaId + "&keyword=" + keyword, {
      method: "GET"
    }).then(res => {

      if (res.status >= 200 && res.status < 400) {
        return res.text();
      }

      return false;
    }).then(html => {
      if(html == false) {
        return false;
      }

      document.getElementById('righe-esistenze').innerHTML = html;

      var rotationalTogglers = document.getElementsByClassName('rotational-toggler');

      for (var i = 0; i < rotationalTogglers.length; i++) {
        rotationalTogglers[i].addEventListener('click', function() {
          this.classList.toggle('rotational-toggler-open');
          this.closest('div.matricola-container').querySelector('div.accessori').classList.toggle('hidden');
        });
      }

      return true;
    });
  }
}


if(document.getElementById('modal-vendite')) {
  global.modale.aggiornaModaleVendite = function(codiceProdotto, anno) {
    return fetch("/" + global.lang + "/products/tabella-vendite/" + codiceProdotto + "/" + anno, {
      method: "GET"
    }).then(res => {

      if (res.status >= 200 && res.status < 400) {
        return res.text();
      }

      return false;
    }).then(html => {
      if(html == false) {
        return false;
      }

      document.getElementById('righe-vendite').innerHTML = html;

      return true;
    });
  }
}

global.menu = {
  active: null,
  toggle: function (menuElement) {
    console.log(menuElement)
    if(menuElement.style.height == '0px') {
      menu.mostra(menuElement)
    } else {
      menu.nascondi(menuElement)
    }
  },
  mostra: function(menuElement) {

    if(menu.active !== null) {
      menu.nascondi(menu.active);
    }

    menuElement.style.height = 'calc(100% - 55px)';

    menu.active = menuElement;
  },
  nascondi: function(menuElement) {
    menu.active = null;

    menuElement.style.height = '0px';
  }
}

global.ui = {
  espandi: function(element) {
    let dati = $(element).closest($(element).attr('parent-selector')).find($(element).attr('selector'));

    dati.toggleClass('hidden');

    $(element).find('.espandi-open').toggleClass('hidden');
    $(element).find('.espandi-close').toggleClass('hidden');
  },
  reloadLazy: function() {
    let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    let videos = [].slice.call(document.getElementsByTagName("video"));

    if ("IntersectionObserver" in window) {

      let videoObserver = new IntersectionObserver(function(entries, observer) {

        // Loop through IntersectionObserverEntry objects
        entries.forEach(function(entry) {
          // Do these if the target intersects with the root
          let video = entry.target;
          if (entry.isIntersecting) {
            video.play();
            videoObserver.unobserve(video);
          } else {
            video.pause();
          }
        });
      }, {
        threshold: 0.5,
      });

      // Loop through and observe each image
      lazyImages.forEach(function(lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });

      videos.forEach(function(video) {
        if(!video.classList.contains('manual-video')) {
          videoObserver.observe(video);
        }
      });

    }
  }
}

global.loaderBlock = {
  show: function() {
    document.getElementById('loader-block').classList.remove('hidden');
  },
  hide: function() {
    document.getElementById('loader-block').classList.add('hidden');
  },
  text: function(text) {
    document.getElementById('loader-text').textContent = text;
  }
}
